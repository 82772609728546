import React from 'react';
import { confirmable, ConfirmDialog } from 'react-confirm';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { ProcessDto } from '../../services/process-service/process.service';
import { createConfirmation } from '../../shared/confirm-dialog';
import { Trans, useTranslation } from 'react-i18next';
import {
  SHIPPING_UNIT_BARCODE_PREFIX,
  SHIPPING_UNIT_BARCODE_REGEX,
  ShippingService,
} from '../../services/shipping-service/shipping.service';
import { ShippingUnitState } from '../../shared/backend';
import { BarcodeReader } from './BarcodeReader';
import { useNotifications } from '../../hooks/useNotifications';
import { printBase64PDF } from '../../shared/helper/print';
import CreateShippingUnitDialog from './CreateShippingUnitDialog';
import Clear from '@mui/icons-material/Clear';
import { ProcessTypeService } from '../../services/process-type-service/process-type.service';

const GLS_MAX_WEIGHT = 30_000;

type Props = {
  process: ProcessDto;
  shippingWeight: number | null;
};

const ShippingUnitDialog: ConfirmDialog<Props, string> = ({ process, shippingWeight, proceed, show }) => {
  const { t } = useTranslation();
  const notifications = useNotifications();
  const processTypes = ProcessTypeService.useProcessTypes();
  const processType = processTypes.data?.find(
    ({ processTypeId }) => processTypeId === process.processType.processTypeId,
  );
  const warehouse = processType?.inboundWarehouse;
  const units = ShippingService.useShippingUnits(
    processType
      ? {
          warehouse,
          customerId: process.customerId,
          returnWarehouseAddressId: process.returnWarehouseAddressId,
          state: ShippingUnitState.OPEN,
        }
      : null,
  );

  if (units.isLoading) {
    return (
      <Dialog open={show}>
        <CircularProgress />
      </Dialog>
    );
  }

  if (units.data.length > 0) {
    const openUnit = units.data[0];
    const maxWeightExceeded = openUnit.weight + (shippingWeight ?? 0) > GLS_MAX_WEIGHT;

    return (
      <Dialog open={show}>
        <DialogTitle>
          {warehouse ? `${warehouse}: ` : ''}
          {t('Add to shipping unit')}
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            <Trans shouldUnescape>
              We found an open shipping unit which was created on{' '}
              {{ createdAt: new Date(openUnit.createdAt).toLocaleString() }} and contains {{ size: openUnit.size }}{' '}
              shipments. Please scan the barcode of the unit to assign the shipment.
            </Trans>
          </DialogContentText>

          {!!openUnit.note && (
            <Alert
              severity="info"
              sx={{ mt: 2 }}
            >
              <AlertTitle>{t('Note')}</AlertTitle>

              {openUnit.note}
            </Alert>
          )}

          {maxWeightExceeded && (
            <Alert
              severity="error"
              sx={{ mt: 2 }}
            >
              <AlertTitle>{t('Maximum weight exceeded')}</AlertTitle>

              <Trans>
                The shipping unit has currently a weight of{' '}
                {{ currentWeight: `${(openUnit.weight / 1000).toFixed(2)} kg` }} and together with this shipment it will
                exceed the maximum weight of {{ maxWeight: `${(GLS_MAX_WEIGHT / 1000).toFixed(2)} kg` }}. Please close
                the current shipping unit before continuing.
              </Trans>
            </Alert>
          )}
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => proceed('')}
            color="error"
            startIcon={<Clear />}
          >
            {t('Abort')}
          </Button>

          <Box flexGrow={1}></Box>

          <BarcodeReader
            disabled={maxWeightExceeded}
            focus
            handleScanBarcode={async (barcode) => {
              if (!SHIPPING_UNIT_BARCODE_REGEX.test(barcode)) {
                return false;
              }

              if (units.data.find((unit) => unit.barcode === barcode)) {
                proceed(barcode);

                return true;
              }

              notifications.addWarning(t('Our open shipping unit has a different barcode.'));

              return false;
            }}
            onSubmit={() => undefined}
          />
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <CreateShippingUnitDialog
      processId={process.processId}
      show={show}
      close={() => proceed('')}
      create={async (note) => {
        const createdUnit = await ShippingService.createShippingUnit(process.processId, note);

        notifications.addSuccess(
          t('New shipping unit with barcode {{barcode}} created.', {
            barcode: `${SHIPPING_UNIT_BARCODE_PREFIX}${createdUnit.shippingUnitId}`,
          }),
        );

        await printBase64PDF(createdUnit.label);

        await units.mutate();
      }}
    />
  );
};

export const confirmShippingUnit = createConfirmation(confirmable(ShippingUnitDialog));
