import React from 'react';
import DataTable, { TDataTableColumn } from '../../../shared/components/data-table/DataTable';
import DeleteButton from '../../../shared/components/delete/DeleteButton';
import { useNotifications } from '../../../hooks/useNotifications';
import { CustomsService } from '../../../services/customs-service/customs.service';
import { Box, Button, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import { Add, Clear } from '@mui/icons-material';
import { IPersonInCharge } from '../../../services/customs-service/customs.types';
import { useForm } from 'react-hook-form';
import FormTextField from '../../../shared/components/react-hook-form/FormTextField';
import { useUserInfo } from '../../../services/user-service/user.service';
import { useTranslation } from 'react-i18next';

type Props = {};

const PersonInCharge: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { data: userInfo } = useUserInfo();
  const notifications = useNotifications();
  const personInCharge = CustomsService.usePersonsInCharge();

  const form = useForm<Omit<IPersonInCharge, 'consignmentPersonInChargeId'>>({
    mode: 'onTouched',
  });

  const columns: TDataTableColumn<IPersonInCharge>[] = [
    {
      title: 'Id',
      field: 'consignmentPersonInChargeId',
    },
    {
      title: 'Vorname',
      render: ({ forename, email }) =>
        email === userInfo?.email ? (
          <>
            {forename}&nbsp;
            <Box
              component="span"
              sx={{ fontWeight: 'normal' }}
            >
              (aka. bester Mitarbeitende)
            </Box>
          </>
        ) : (
          <>{forename}</>
        ),
    },
    {
      title: 'Nachname',
      field: 'surname',
    },
    {
      title: 'Tel.',
      field: 'phone',
    },
    {
      title: 'Email',
      field: 'email',
    },
    {
      title: 'Initialien',
      field: 'initials',
    },
    {
      title: '',
      render: (row) => (
        <DeleteButton
          justifyContent="flex-end"
          onClick={async () => {
            try {
              await CustomsService.deletePersonInChargeById(row.consignmentPersonInChargeId);

              personInCharge.mutate();

              notifications.addSuccess('Eintrag gelöscht');
            } catch (error) {
              notifications.addError(error);
            }
          }}
        />
      ),
      cellProps: {
        align: 'right',
      },
    },
  ];

  return (
    <>
      <Typography
        variant="h5"
        mb={3}
      >
        {t('Person in charge')}
      </Typography>

      <DataTable
        columns={columns}
        rows={personInCharge.data}
        getRowKey={(row) => row.consignmentPersonInChargeId}
        highlightPredicate={(row) => row.email === userInfo?.email}
      />

      <form
        onSubmit={form.handleSubmit(async (data) => {
          try {
            await CustomsService.createPersonInCharge(data);

            personInCharge.mutate();

            form.reset();

            notifications.addSuccess('Person hinzugefügt');
          } catch (error) {
            notifications.addError(error);
          }
        })}
        noValidate
      >
        <Grid
          container
          spacing={1}
          mt={3}
        >
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
          >
            <FormTextField
              name="forename"
              label="Vorname"
              size="small"
              required
              fullWidth
              control={form.control}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={4}
          >
            <FormTextField
              name="surname"
              label="Nachname"
              size="small"
              required
              fullWidth
              control={form.control}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={4}
          >
            <FormTextField
              name="phone"
              label="Telefon"
              size="small"
              required
              fullWidth
              control={form.control}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={4}
          >
            <FormTextField
              name="email"
              label="E-Mail"
              size="small"
              type="email"
              rules={{
                pattern: {
                  value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                  message: 'E-Mail Adress ungültig',
                },
              }}
              required
              fullWidth
              control={form.control}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={4}
          >
            <FormTextField
              name="initials"
              label="Initialen"
              size="small"
              rules={{ maxLength: { value: 3, message: 'Maximal 3 Zeichen' } }}
              required
              fullWidth
              control={form.control}
            />
          </Grid>

          <Grid
            item
            xs={12}
          >
            <Stack
              spacing={1}
              direction="row"
              justifyContent="flex-end"
            >
              <Button
                size="small"
                onClick={() => form.reset()}
                startIcon={<Clear />}
                disabled={!form.formState.isDirty}
              >
                {t('Reset')}
              </Button>

              <Button
                size="small"
                variant="contained"
                type="submit"
                startIcon={form.formState.isSubmitting ? <CircularProgress sx={{ fontSize: '1em' }} /> : <Add />}
                disabled={!form.formState.isValid || form.formState.isSubmitting}
              >
                {t('Add')}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default PersonInCharge;
