import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { StyledEngineProvider, Theme } from '@mui/material';
import { ScaleProvider } from './contexts/ScaleContext';
import { SnackbarProvider } from 'notistack';
import { SWRProvider } from './contexts/SWRProvider';
import { createRoot } from 'react-dom/client';
import { Config } from './shared/helper/env/helper';
import { ExportoEnv } from './shared/helper/globals';
import './translations/i18n';
declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

declare global {
  interface Window {
    /** Matomo control object. */
    _paq: (string | number)[][];
  }
}

window._paq = window._paq || [];
window._paq.push(['requireCookieConsent']);
window._paq.push(['enableLinkTracking']);
(function () {
  const siteId = Config.MATOMO.SITE_ID;
  const trackerUrl = Config.MATOMO.TRACKER_URL;

  if (siteId && trackerUrl) {
    window._paq.push(['setTrackerUrl', trackerUrl]);
    window._paq.push(['setSiteId', siteId]);
    const d = document,
      g = d.createElement('script'),
      s = d.getElementsByTagName('body')[0];
    g.async = true;
    g.src = `${Config.PUBLIC_URL}/matomo.js`;
    s.append(g);
  }
})();

const defaultTitle =
  process.env.NODE_ENV === 'production'
    ? Config.ENV === ExportoEnv.staging
      ? 'exporto Staging'
      : 'exporto'
    : 'exporto Dev';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  /*
   * If you are looking into this file, because you see some initial re-renders,
   * you should know that this is intentional by strict mode to detect
   * unexpected side effects. See
   * https://legacy.reactjs.org/docs/strict-mode.html#detecting-unexpected-side-effects
   */
  <React.StrictMode>
    <HelmetProvider>
      <Helmet
        defaultTitle={defaultTitle}
        titleTemplate="%s | exporto"
      ></Helmet>
      <SnackbarProvider maxSnack={5}>
        <SWRProvider>
          <ScaleProvider>
            <StyledEngineProvider injectFirst>
              <App />
            </StyledEngineProvider>
          </ScaleProvider>
        </SWRProvider>
      </SnackbarProvider>
    </HelmetProvider>
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
