import { Box, Button, CircularProgress, IconButton, Paper, Stack, Typography } from '@mui/material';
import React from 'react';
import { ProcessService, ProcessWithCustomerDto } from '../../../services/process-service/process.service';
import { countryToFlag } from '../../../shared/helper/country';
import BlockIcon from '@mui/icons-material/Block';
import ProcessDateLabel from './settings/ProcessDateLabel';
import { isToday } from '../../../shared/helper/date';
import { DateTime } from 'luxon';
import HistoryIcon from '@mui/icons-material/History';
import * as TaskService from '../../../services/task-service/task.service';
import ArticleIcon from '@mui/icons-material/Article';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useNotifications } from '../../../hooks/useNotifications';
import { LoadingButton } from '@mui/lab';
import ReplyIcon from '@mui/icons-material/Reply';
import EmailIcon from '@mui/icons-material/Email';
import { getProcessTypeLabel } from '../../../services/process-type-service/process-type.service';
import { useTranslation } from 'react-i18next';
import { ReturnMailNotification } from '../../../shared/backend';

type Props = {
  process: ProcessWithCustomerDto;
};

const ProcessDetails: React.FC<Props> = ({ process }) => {
  const { t } = useTranslation('CRM');
  const notifications = useNotifications();
  const processStats = ProcessService.useProcessStats(process.processId);
  const { mutate } = ProcessService.useProcess(process.processId);

  const processTypeLabel = getProcessTypeLabel(process.processType);
  const createdAt = new Date(process.createdAt);

  const handleImportArticles = async () => {
    notifications.addInfo(t('Time for a hot drink. The article import is running and could take over an hour.'));

    TaskService.importArticles(process.processId)
      .then(() => {
        mutate();
      })
      .catch((err: unknown) => {
        notifications.addError(err);
      });

    setTimeout(() => mutate({ ...process, articleImporterRunning: 'yes' }), 500);
  };

  const handleImportOrders = async () => {
    notifications.addInfo(t('Time for a cold drink. Orders are imported and that can take time.'));
    TaskService.importOrders(process.processId)
      .then(() => {
        mutate();
      })
      .catch((err: unknown) => {
        notifications.addError(err);
      });

    setTimeout(() => mutate({ ...process, orderImporterRunning: 'yes' }), 500);
  };

  const handleSendReturnEmail = async () => {
    try {
      if (process) {
        notifications.addInfo(t('Sending e-mail...'));
        await ProcessService.sendRetoureDigest(process.processId);
      }
    } catch (error) {
      notifications.addError(error);
    }
  };

  return (
    <Paper sx={{ padding: 1, paddingTop: 2 }}>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={3}
        flexWrap="wrap"
        justifyContent="flex-end"
      >
        <Stack
          direction="row"
          spacing={1}
        >
          <Box pt="1.2em">
            {process.blocked ? (
              <BlockIcon
                color="error"
                sx={{ fontSize: '2em' }}
              />
            ) : (
              <Box
                component="span"
                sx={{ fontSize: '2em' }}
              >
                {countryToFlag(process.processType.destinationCountry)}
              </Box>
            )}
          </Box>
          <Box>
            <Typography
              variant="body2"
              color="text.secondary"
            >
              {processTypeLabel}
            </Typography>
            <Typography
              variant="h4"
              sx={{ whiteSpace: 'nowrap' }}
            >
              {t('Process {{processId}}', { ns: 'CRM', processId: process.processId })}
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              gutterBottom
              sx={{ whiteSpace: 'nowrap' }}
            >
              {isToday(createdAt) ? createdAt.toLocaleTimeString() : createdAt.toLocaleString()}
            </Typography>
          </Box>
        </Stack>

        <Box flexGrow={1}></Box>

        <Box
          pl={1}
          pr={2}
        >
          <Typography
            variant="body2"
            color="text.secondary"
          >
            {t('Article')}
          </Typography>
          <Typography variant="h5">{processStats.data?.articleCount ?? <CircularProgress size="1em" />}</Typography>
        </Box>

        <Box
          pl={1}
          pr={2}
        >
          <Typography
            variant="body2"
            color="text.secondary"
          >
            {t('Orders')}
          </Typography>
          <Typography variant="h5">{processStats.data?.orderCount ?? <CircularProgress size="1em" />}</Typography>
        </Box>

        <Box
          pl={1}
          pr={2}
        >
          <Typography
            variant="body2"
            color="text.secondary"
          >
            {t('Consignments')}
          </Typography>
          <Typography variant="h5">
            {processStats.data?.outboundShipmentCount ?? <CircularProgress size="1em" />}
          </Typography>
        </Box>

        <Box
          pl={1}
          pr={2}
        >
          <Typography
            variant="body2"
            color="text.secondary"
          >
            {t('Returns')}
          </Typography>
          <Typography variant="h5">
            {processStats.data?.inboundShipmentCount ?? <CircularProgress size="1em" />}
          </Typography>
        </Box>
      </Stack>

      <ProcessDateLabel process={process} />

      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={1}
        mt={3}
        mb={1}
      >
        <Button
          disabled={!!process.articleReferenceId || !!process.articleImporterRunning || !process.shopSystem}
          size="small"
          variant="contained"
          onClick={handleImportArticles}
          startIcon={process.articleImporterRunning ? <CircularProgress size="1em" /> : <ArticleIcon />}
        >
          {process.articleImporterRunning ? t('Article import running') : t('Start article import')}
        </Button>

        <Button
          size="small"
          variant="contained"
          onClick={handleImportOrders}
          disabled={!!process.orderImporterRunning || !process.shopSystem}
          startIcon={process.orderImporterRunning ? <CircularProgress size="1em" /> : <ShoppingCartIcon />}
        >
          {process.orderImporterRunning ? t('Order import running') : t('Start order import')}
        </Button>

        <LoadingButton
          disabled={process.returnMailNotification === ReturnMailNotification.DISABLED}
          size="small"
          variant="contained"
          startIcon={<EmailIcon />}
          onClick={handleSendReturnEmail}
        >
          {t('Send returns e-mail')}
        </LoadingButton>

        <Button
          component="a"
          size="small"
          startIcon={<ReplyIcon />}
          href={`/crm/customers/${process.customerId}/settings/${process.processId}/return`}
        >
          {t('Returns settings')}
        </Button>
      </Stack>

      {(process.lastArticleImport || process.lastOrderImport) && (
        <Typography
          variant="body2"
          color="text.disabled"
        >
          {process.lastArticleImport && (
            <span>
              {t('Last article import {{relativeLastArticleImport}}.', {
                relativeLastArticleImport: DateTime.fromISO(process.lastArticleImport).setLocale('de').toRelative(),
              })}
            </span>
          )}
          {process.lastOrderImport && (
            <span> Letzter Order-Import {DateTime.fromISO(process.lastOrderImport).setLocale('de').toRelative()}.</span>
          )}

          <IconButton
            component="a"
            href={`/activity?action=importer&entityId=${process.processId}`}
            target="_blank"
          >
            <HistoryIcon />
          </IconButton>
        </Typography>
      )}
    </Paper>
  );
};

export default ProcessDetails;
