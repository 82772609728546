import { Chip } from '@mui/material';
import React from 'react';
import { ProcessTypeDto } from '../../services/process-type-service/process-type.service';

type Props = {
  processType: ProcessTypeDto;
};

const ProcessTypeLabel: React.FC<Props> = ({ processType }) => {
  const label = processType.label || `${processType.dispatchCountry} / ${processType.destinationCountry}`;

  return (
    <>
      {label}{' '}
      {processType.outboundTag && (
        <Chip
          label={processType.outboundTag}
          color="outbound"
          size="small"
        />
      )}{' '}
      {processType.inboundTag && (
        <Chip
          label={processType.inboundTag}
          color="inbound"
          size="small"
        />
      )}
    </>
  );
};

export default ProcessTypeLabel;
