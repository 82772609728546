import React from 'react';
import { ConsignmentPartyDto, CustomsService } from '../../../services/customs-service/customs.service';
import { useNotifications } from '../../../hooks/useNotifications';
import { useForm } from 'react-hook-form';
import DataTable, { TDataTableColumn } from '../../../shared/components/data-table/DataTable';
import { Clear, Add } from '@mui/icons-material';
import { Typography, Grid, Stack, Button, CircularProgress, IconButton } from '@mui/material';
import DeleteButton from '../../../shared/components/delete/DeleteButton';
import FormTextField from '../../../shared/components/react-hook-form/FormTextField';
import FormSelectCountryCode from '../../../shared/components/react-hook-form/FormSelectCountryCode';
import { IsoCountryCode } from '../../../shared/backend';
import { countryToFlag } from '../../../shared/helper/country';
import FormCheckbox from '../../../shared/components/react-hook-form/FormCheckbox';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';

type Props = {};

const ConsignmentParty: React.FC<Props> = () => {
  const { t } = useTranslation();
  const notifications = useNotifications();
  const consignmentParties = CustomsService.useConsignmentParties();

  const form = useForm<ConsignmentPartyDto>({
    mode: 'onTouched',
  });

  const handleCopyDataToForm = (data: ConsignmentPartyDto) => {
    for (const key of Object.keys(data)) {
      if (!['createdAt', 'updatedAt'].includes(key)) {
        const value = data[key as keyof Omit<ConsignmentPartyDto, 'consignmentPartyId'>];

        if (value !== null) {
          form.setValue(key as keyof ConsignmentPartyDto, value);
        }
      }
    }
  };

  const columns: TDataTableColumn<ConsignmentPartyDto>[] = [
    {
      title: t('Id'),
      field: 'consignmentPartyId',
    },
    {
      title: t('Name'),
      field: 'name',
    },
    {
      title: t('Name 2'),
      field: 'name2',
    },
    {
      title: t('Street'),
      field: 'street',
    },
    {
      title: t('City'),
      field: 'city',
    },
    {
      title: t('Post code'),
      field: 'postCode',
    },
    {
      title: t('Country'),
      render: ({ country }) => (
        <>
          <span>{countryToFlag(country)}</span>&nbsp;
          {country}
        </>
      ),
    },
    {
      title: t('Company number'),
      field: 'companyNumber',
    },
    {
      title: t('EORI number'),
      field: 'eoriNumber',
    },
    {
      title: t('Transporter'),
      render: ({ isCarrier }) => (isCarrier ? <DoneIcon color={'success'} /> : <ClearIcon color={'error'} />),
      cellProps: {
        align: 'center',
      },
    },
    {
      title: t('Transit consignor'),
      render: ({ isTransitConsignor }) =>
        isTransitConsignor ? <DoneIcon color={'success'} /> : <ClearIcon color={'error'} />,
      cellProps: {
        align: 'center',
      },
    },
    {
      title: t('Place of departure'),
      render: ({ isShippingPoint }) =>
        isShippingPoint ? <DoneIcon color={'success'} /> : <ClearIcon color={'error'} />,
      cellProps: {
        align: 'center',
      },
    },
    {
      title: '',
      render: (row) => (
        <IconButton
          onClick={async () => {
            handleCopyDataToForm(row);
          }}
        >
          <EditIcon />
        </IconButton>
      ),
      cellProps: {
        align: 'right',
      },
    },
    {
      title: '',
      render: (row) => (
        <DeleteButton
          justifyContent="flex-end"
          onClick={async () => {
            try {
              await CustomsService.deleteConsignmentPartyById(row.consignmentPartyId);

              form.resetField('consignmentPartyId');
              consignmentParties.mutate();

              notifications.addSuccess('Carrier gelöscht');
            } catch (error) {
              notifications.addError(error);
            }
          }}
        />
      ),
      cellProps: {
        align: 'right',
      },
    },
  ];

  return (
    <>
      <Typography
        variant="h5"
        mb={3}
      >
        {t('Transporters, Transit consignors and places of departure.')}
      </Typography>

      <DataTable
        columns={columns}
        rows={consignmentParties.data}
        getRowKey={(row) => row.consignmentPartyId}
      />

      <form
        onSubmit={form.handleSubmit(async (data) => {
          try {
            const { consignmentPartyId, ...createData } = data;
            await CustomsService.createConsignmentParty(createData);

            if (!!consignmentPartyId) {
              await CustomsService.deleteConsignmentPartyById(consignmentPartyId);
            }

            consignmentParties.mutate();

            form.reset();

            notifications.addSuccess(form.getValues('consignmentPartyId') ? t('Entry updated') : t('Entry added'));
          } catch (error) {
            notifications.addError(error);
          }
        })}
        noValidate
      >
        <Grid
          container
          spacing={1}
          mt={3}
        >
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
          >
            <FormTextField
              name="consignmentPartyId"
              label={t('Id')}
              size="small"
              disabled={true}
              fullWidth
              control={form.control}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={4}
          >
            <FormTextField
              name="name"
              label={t('Name')}
              size="small"
              required
              fullWidth
              control={form.control}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={4}
          >
            <FormTextField
              name="name2"
              label={t('Name 2')}
              size="small"
              fullWidth
              control={form.control}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={4}
          >
            <FormTextField
              name="street"
              label={t('Street')}
              size="small"
              required
              fullWidth
              control={form.control}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={4}
          >
            <FormTextField
              name="city"
              label={t('City')}
              size="small"
              required
              fullWidth
              control={form.control}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={4}
          >
            <FormTextField
              name="postCode"
              label={t('Post code')}
              size="small"
              required
              fullWidth
              control={form.control}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={4}
          >
            <FormSelectCountryCode
              name="country"
              label={t('Country')}
              size="small"
              required
              fullWidth
              control={form.control}
              commonCountryCodes={[IsoCountryCode.DE, IsoCountryCode.CH, IsoCountryCode.GB, IsoCountryCode.NL]}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={4}
          >
            <FormTextField
              name="companyNumber"
              label={t('Company number')}
              size="small"
              fullWidth
              control={form.control}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={4}
          >
            <FormTextField
              name="eoriNumber"
              label={t('EORI number')}
              size="small"
              fullWidth
              control={form.control}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={4}
          >
            <FormCheckbox
              name="isCarrier"
              label={t('Transporter')}
              size="small"
              control={form.control}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={4}
          >
            <FormCheckbox
              name="isTransitConsignor"
              label={t('Transit consignor')}
              size="small"
              control={form.control}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={4}
          >
            <FormCheckbox
              name="isShippingPoint"
              label={t('Place of departure')}
              size="small"
              control={form.control}
            />
          </Grid>

          <Grid
            item
            xs={12}
          >
            <Stack
              spacing={1}
              direction="row"
              justifyContent="flex-end"
            >
              <Button
                size="small"
                onClick={() => {
                  form.reset();
                }}
                startIcon={<Clear />}
                disabled={!form.formState.isDirty}
              >
                {!form.getValues('consignmentPartyId') ? t('Abort') : t('Reset')}
              </Button>

              <Button
                size="small"
                variant="contained"
                type="submit"
                startIcon={form.formState.isSubmitting ? <CircularProgress sx={{ fontSize: '1em' }} /> : <Add />}
                disabled={!form.formState.isValid || form.formState.isSubmitting}
              >
                {form.getValues('consignmentPartyId') ? t('Save') : t('Add')}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default ConsignmentParty;
