import {
  Checkbox,
  Radio,
  FormControl,
  Select,
  InputAdornment,
  IconButton,
  Stack,
  ListItemIcon,
  ListSubheader,
  TextField,
} from '@mui/material';
import Chip from '@mui/material/Chip';
import SearchIcon from '@mui/icons-material/Search';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import { default as React, FC, useState, useRef } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import { CustomerDto } from '../../../services/customer-service/customer.service';
import { ProcessDto } from '../../../services/process-service/process.service';
import { useTranslation } from 'react-i18next';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export interface ICustomerFilterDto extends CustomerDto {
  compatibleProcesses?: ProcessDto[];
}

export interface CustomerFilterProps {
  customers: ICustomerFilterDto[];
  selectedCustomerIds: number[];
  onChange: (customerIds: number[]) => void;
  multiple?: boolean;
  size?: 'small' | 'medium';
  fullWidth?: boolean;
  required?: boolean;
  disabled?: boolean;
}

function compareCustomersAlphabetically(a: CustomerDto, b: CustomerDto): number {
  if (a.customerId === 0) {
    return -1;
  }
  return a.company.toUpperCase() > b.company.toUpperCase() ? 1 : -1;
}

export const CustomerFilter: FC<CustomerFilterProps> = (props) => {
  const { t } = useTranslation();
  const { customers, selectedCustomerIds, onChange } = props;

  const [searchText, setSearchText] = useState('');
  const searchInputRef = useRef<HTMLInputElement>();

  /**
   * Generate label for customer dropdown option
   */
  function generateLabelForCustomer(customer: ICustomerFilterDto): string {
    const baseLabel = `${customer.company}`;
    const processInfo = customer.compatibleProcesses
      ? `(${customer.compatibleProcesses.map((process) => process.processId).join(', ')})`
      : '';
    return `${baseLabel} ${processInfo}`;
  }

  const renderMultipleValues = () => {
    return (
      <Stack
        direction="row"
        spacing={1}
      >
        {selectedCustomerIds
          .map((id) => customers.find((c) => c.customerId === id))
          .map(
            (customer) =>
              customer && (
                <Chip
                  size="small"
                  color="secondary"
                  key={customer.customerId}
                  label={generateLabelForCustomer(customer)}
                />
              ),
          )}
      </Stack>
    );
  };

  const renderSingleValue = () => {
    const customer = customers.find((c) => c.customerId === selectedCustomerIds[0]);

    return <>{customer && generateLabelForCustomer(customer)}</>;
  };

  const value =
    customers.length > 0 ? selectedCustomerIds.filter((id) => !!customers.find((c) => c.customerId === id)) : [];

  const options = !searchText.trim()
    ? customers
    : customers.filter((customer) => customer.company.toLowerCase().includes(searchText.trim().toLowerCase()));

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
    >
      <FormControl
        size={props.size ?? 'small'}
        variant="outlined"
        sx={{ minWidth: 120 }}
        fullWidth={props.fullWidth ?? false}
        required={props.required ?? false}
        disabled={customers.length === 0 || props.disabled}
      >
        {value.length === 0 && (
          <InputLabel
            sx={{ mt: 0.5 }}
            id="customer-filter-label"
          >
            {t('Customers')}
          </InputLabel>
        )}
        <Select
          autoWidth
          sx={{ minHeight: 41 }}
          labelId="customer-filter-label"
          label={value.length === 0 ? 'Kunden' : undefined}
          multiple={props.multiple}
          onChange={(event) =>
            onChange(Array.isArray(event.target.value) ? event.target.value : [Number(event.target.value)])
          }
          value={props.multiple ? value : value[0] || ''}
          renderValue={props.multiple ? renderMultipleValues : renderSingleValue}
          MenuProps={{
            autoFocus: false,
            PaperProps: {
              style: {
                maxHeight: ITEM_HEIGHT * 10 + ITEM_PADDING_TOP,
              },
            },
          }}
          startAdornment={
            selectedCustomerIds.length > 0 &&
            props.multiple && (
              <InputAdornment position="start">
                <IconButton
                  onClick={() => onChange([])}
                  edge="start"
                  size="small"
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              </InputAdornment>
            )
          }
          onAnimationEnd={() => searchInputRef.current?.focus()}
          onClose={() => setSearchText('')}
        >
          <ListSubheader
            disableGutters
            sx={{ px: 1 }}
          >
            <TextField
              inputRef={searchInputRef}
              size="small"
              autoFocus
              placeholder="Search"
              fullWidth
              value={searchText}
              inputProps={{ autoFocus: true }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon
                      sx={{ ml: -1 }}
                      color="disabled"
                    />
                  </InputAdornment>
                ),
                endAdornment: searchText.trim() ? (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setSearchText('')}
                      edge="end"
                      size="small"
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ) : undefined,
              }}
              onChange={(ev) => setSearchText(ev.target.value)}
              onKeyDown={(ev) => {
                if (ev.key !== 'Escape') {
                  ev.stopPropagation();
                }

                if (ev.key === 'Enter' && options.length === 1) {
                  if (selectedCustomerIds.includes(options[0].customerId)) {
                    onChange(selectedCustomerIds.filter((id) => id !== options[0].customerId));
                  } else {
                    if (props.multiple) {
                      onChange([...selectedCustomerIds, options[0].customerId]);
                    } else {
                      onChange([options[0].customerId]);
                    }
                  }

                  setSearchText('');
                }
              }}
            />
          </ListSubheader>
          {options?.sort(compareCustomersAlphabetically).map((customer) => {
            const checked = selectedCustomerIds.includes(customer.customerId);

            return (
              <MenuItem
                key={customer.customerId}
                value={customer.customerId}
                sx={{ pl: 0 }}
              >
                <ListItemIcon>
                  {props.multiple ? <Checkbox checked={checked} /> : <Radio checked={checked} />}
                </ListItemIcon>
                <ListItemText primary={generateLabelForCustomer(customer)} />
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Stack>
  );
};
