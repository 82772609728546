import {
  Button,
  Card,
  CardHeader,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  SelectChangeEvent,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import DeleteButton from '../../../../../../../shared/components/delete/DeleteButton';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import { Controller, useForm } from 'react-hook-form';

import React, { FC, Fragment } from 'react';
import {
  AddressDto,
  CreateAddressDto,
  UpdateAddressDto,
} from '../../../../../../../services/address-service/address.service';
import { getTypedObjectEntries } from '../../../../../../../shared/helper/helper';
import { SelectFederalState } from './SelectFederalState';
import SelectCountryCode from '../../../../../../../shared/components/SelectCountryCode';
import { ORIGIN_AREAS } from '../../../../../../../services/process-service/process.service';
import { useTranslation } from 'react-i18next';
import { Warning } from '@mui/icons-material';

interface AddressFormFields {
  addressId: string;
  name: string;
  street: string;
  zip: string;
  city: string;
  country: string;
  federalState: string;
  eoriNumber: string;
}
const addressFormFields = [
  'addressId',
  'name',
  'street',
  'zip',
  'city',
  'country',
  'federalState',
  'eoriNumber',
] as const;

export interface AddressFormProps {
  address: AddressDto | null;
  onAddressCreate: (createValues: CreateAddressDto) => Promise<void>;
  onAddressUpdate: (addressId: number, updateValues: UpdateAddressDto) => Promise<void>;
  onAddressDelete: (addressId: number) => Promise<void>;
  cancelAddressCreate?: () => void;
}

const AddressForm: FC<AddressFormProps> = (props: AddressFormProps) => {
  const { t } = useTranslation('CRM');

  const {
    handleSubmit,
    formState: { isDirty, isSubmitting },
    reset,
    control,
    watch,
    setValue,
  } = useForm<AddressFormFields>({
    defaultValues: {
      addressId: props.address?.addressId.toString() ?? '',
      name: props.address?.name.toString() ?? '',
      street: props.address?.street.toString() ?? '',
      zip: props.address?.zip.toString() ?? '',
      city: props.address?.city.toString() ?? '',
      country: props.address?.country.toString() ?? '',
      federalState: props.address?.federalState.toString() ?? '',
      eoriNumber: props.address?.eoriNumber.toString() ?? '',
    },
  });

  const currentName = watch('name');
  const currentCountry = watch('country');
  const currentFederalState = watch('federalState');

  const onSubmit = async (formFields: AddressFormFields) => {
    props.address ? await handleUpdateAddress(formFields) : await handleCreateAddress(formFields);
    // set isDirty to false
    reset(formFields);
  };

  const handleUpdateAddress = async (formFields: AddressFormFields) => {
    if (props.address) {
      let updateValues: UpdateAddressDto = {};

      for (const [key, value] of getTypedObjectEntries(formFields)) {
        if (key === 'federalState') {
          updateValues[key] = Object.keys(ORIGIN_AREAS).includes(value)
            ? (value.trim() as keyof typeof ORIGIN_AREAS)
            : '';
        } else if (key !== 'addressId') {
          updateValues[key] = value.trim();
        }
      }

      await props.onAddressUpdate(props.address.addressId, updateValues);
    }
  };

  const handleCreateAddress = (formFields: AddressFormFields): Promise<void> => {
    let newAddress: CreateAddressDto = {
      name: '',
      street: '',
      zip: '',
      city: '',
      country: '',
      federalState: '',
      eoriNumber: '',
    };

    for (const [key, value] of getTypedObjectEntries(formFields)) {
      if (key === 'federalState') {
        newAddress[key] = value.trim() as keyof typeof ORIGIN_AREAS;
      } else if (key !== 'addressId') {
        newAddress[key] = value.trim();
      }
    }

    return props.onAddressCreate(newAddress);
  };

  const getFormFieldLabel = (formField: keyof AddressFormFields): string => {
    const labels: { [key in (typeof addressFormFields)[number]]: string } = {
      addressId: t('Id'),
      name: t('Name'),
      street: t('Street'),
      zip: t('Zip'),
      city: t('City'),
      country: t('Country'),
      federalState: t('Federal state'),
      eoriNumber: t('Eori'),
    };
    return labels[formField];
  };

  const handleDeleteAddress = () => {
    if (props.address) {
      props.onAddressDelete(props.address.addressId);
    }
  };

  const handleChange = (
    formField: keyof AddressFormFields,
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | SelectChangeEvent<string>,
  ) => {
    if (formField === 'country') {
      if (event.target.value === '' || (event.target.value === 'DE' && currentFederalState === '99')) {
        setValue('federalState', '');
      } else if (event.target.value !== 'DE') {
        setValue('federalState', '99');
      }
    }
  };

  const isFederalStateInvalid = currentCountry === 'DE' && currentFederalState === '99';

  return (
    <Fragment>
      <Card sx={{ mt: 3, p: 1 }}>
        {!props.address && <CardHeader title={t(`New address`)}></CardHeader>}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack
            direction="row"
            flexWrap="nowrap"
            alignItems="center"
            m={1}
          >
            <Grid
              container
              spacing={1}
            >
              {addressFormFields.map((formField) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  xl={2}
                  key={formField}
                >
                  <Controller
                    name={formField}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <FormControl
                        variant="standard"
                        fullWidth
                      >
                        {formField === 'federalState' ? (
                          <SelectFederalState
                            disabled={currentCountry !== 'DE'}
                            error={isFederalStateInvalid}
                            value={value}
                            onChange={(event) => {
                              handleChange(formField, event);
                              onChange(event);
                            }}
                          ></SelectFederalState>
                        ) : formField === 'country' ? (
                          <SelectCountryCode
                            value={value}
                            onChange={(event) => {
                              handleChange(formField, event);
                              onChange(event);
                            }}
                            variant="outlined"
                          />
                        ) : formField === 'name' ? (
                          <TextField
                            fullWidth
                            value={value}
                            onChange={onChange}
                            variant="outlined"
                            InputProps={{
                              endAdornment:
                                currentName.length > 35 ? (
                                  <InputAdornment position="end">
                                    <Tooltip
                                      title={t(
                                        'Name should not be longer than 35 character, if address is used for labels (e.g. shipping unit).',
                                      )}
                                    >
                                      <Warning color="warning" />
                                    </Tooltip>
                                  </InputAdornment>
                                ) : undefined,
                            }}
                          />
                        ) : (
                          <TextField
                            fullWidth
                            value={value}
                            disabled={formField === 'addressId'}
                            onChange={onChange}
                            variant="outlined"
                          />
                        )}
                        <FormHelperText id={`${formField}-helper`}>{getFormFieldLabel(formField)}</FormHelperText>
                      </FormControl>
                    )}
                  />
                </Grid>
              ))}
            </Grid>
          </Stack>

          <Stack
            direction="row"
            sx={{ m: 1, alignItems: 'center' }}
          >
            {props.address && (
              <Fragment>
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={(!!props.address && !isDirty) || isSubmitting || isFederalStateInvalid}
                  startIcon={isSubmitting ? <CircularProgress size="1em" /> : <SaveIcon />}
                  type="submit"
                  sx={{ mr: 2 }}
                >
                  {t('Save')}
                </Button>
                <DeleteButton
                  variant="contained"
                  onClick={handleDeleteAddress}
                />
              </Fragment>
            )}

            {!props.address && (
              <Fragment>
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={isFederalStateInvalid}
                  type="submit"
                  sx={{ mr: 2 }}
                  startIcon={<AddIcon />}
                >
                  {t('Create address')}
                </Button>
                {props.cancelAddressCreate && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      if (props.cancelAddressCreate) {
                        props.cancelAddressCreate();
                      }
                    }}
                  >
                    {t('Cancel')}
                  </Button>
                )}
              </Fragment>
            )}
          </Stack>
        </form>
      </Card>
    </Fragment>
  );
};

export default AddressForm;
