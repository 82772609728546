import { TableRow, TableCell, Typography, Link, IconButton, CircularProgress, Box, Tooltip } from '@mui/material';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import React from 'react';
import BlockIcon from '@mui/icons-material/Block';
import { ShipmentDto, ShipmentService } from '../../services/shipment-service/shipment.service';
import { Stack } from '@mui/system';
import { ArticleService } from '../../services/article-service/article.service';
import { LineItemDto, LineItemService } from '../../services/line-item-service/lineItem.service';
import { useAuthentication } from '../../hooks/useAuthentication';
import { SplitLineItem } from '../orders/shared/components/order-detail-modal/components/shipment-view/components/SplitLineItem';
import { OrderService } from '../../services/order-service/order.service';
import GoodsDescriptionSelector from './GoodsDescriptionSelector';
import { ManageRetoureButtons } from './ManageRetoureButtons';
import { useNotifications } from '../../hooks/useNotifications';
import { ShipmentState } from '../../shared/backend';
import { useBooleanParam } from '../../hooks/useParam';
import { useTranslation } from 'react-i18next';

type Props = {
  shipment: ShipmentDto;
  lineItem: LineItemDto;
  disabled: boolean;
};

const LineItemRow: React.FC<Props> = ({ lineItem, shipment, disabled }) => {
  const notifications = useNotifications();
  const auth = useAuthentication();
  const { t } = useTranslation();
  const [showArticleWeights] = useBooleanParam('showArticleWeights', false);

  const { mutate } = OrderService.useOrderByShipmentId(shipment.shipmentId);
  const article = ArticleService.useArticleByExternalArticleId(lineItem.processId, lineItem.externalArticleId);

  const articleHasNoWeights = !article.data?.weight && !article.data?.defaultWeight;

  if (article.isLoading) {
    return (
      <TableRow>
        <TableCell colSpan={4}>
          <CircularProgress size="small" />
        </TableCell>
      </TableRow>
    );
  }

  return (
    <TableRow>
      <TableCell sx={{ width: '50%' }}>
        {!article.isLoading && !article.data && (
          <Tooltip title={t('Missing article')}>
            <HighlightOffIcon />
          </Tooltip>
        )}
        {article.data?.isBlocked && auth.isStaff() && (
          <BlockIcon
            color="error"
            sx={{ mr: '0.5em', verticalAlign: 'bottom' }}
          />
        )}
        <Typography
          variant="h6"
          component="p"
        >
          <Link
            href={
              article.data?.articleId
                ? `/articles?articleId=${article.data.articleId}`
                : `/articles?search=${lineItem.externalArticleId}`
            }
            underline="hover"
            color="inherit"
            target="_blank"
          >
            {lineItem.name || article.data?.name}
          </Link>
        </Typography>
        {article.data?.name && article.data?.name !== lineItem.name && (
          <Typography component="p">{article.data?.name}</Typography>
        )}
      </TableCell>
      {!auth.isPicker() && (
        <TableCell>
          <Typography
            variant="h6"
            component="p"
          >
            {lineItem.priceGross}
          </Typography>
        </TableCell>
      )}
      <TableCell>
        <Stack
          display="flex"
          direction="row"
          alignItems="center"
        >
          <Typography
            variant="h6"
            component="p"
            display="inline"
          >
            {lineItem.quantity}
          </Typography>
          <SplitLineItem
            item={lineItem}
            reloadShipments={async () => {
              await mutate();
            }}
          />
        </Stack>
      </TableCell>
      {showArticleWeights && (
        <>
          <TableCell>
            <Typography
              component="p"
              color={articleHasNoWeights ? 'error' : undefined}
            >
              {article.data?.weight ?? '-'} g
            </Typography>
          </TableCell>
          <TableCell>
            <Typography
              component="p"
              color={articleHasNoWeights ? 'error' : undefined}
            >
              {article.data?.defaultWeight ?? 0} g
            </Typography>
          </TableCell>
        </>
      )}
      {auth.isStaff() && (
        <TableCell>
          <GoodsDescriptionSelector
            disableShipmentEditing={disabled}
            item={lineItem}
            handleGoodsDescriptionUpdate={async (lineItemId, goodsDescription) => {
              try {
                await LineItemService.updateLineItems([{ lineItemId, goodsDescription }]);
                await mutate();
              } catch (error) {
                notifications.addError(error);
              }
            }}
          />
        </TableCell>
      )}
      <TableCell align="right">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'revert',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          {(auth.isStaff() || (auth.isCustomer() && shipment.state !== ShipmentState.RETOURE_ANNOUNCED)) && (
            <ManageRetoureButtons
              lineItem={lineItem}
              onChangeLineItemState={async (lineItemId, returnState) => {
                try {
                  await LineItemService.updateLineItems([{ lineItemId, returnState }]);
                  await mutate();
                } catch (error) {
                  notifications.addError(error);
                }
              }}
              onLineItemsRefreshAfterSplit={() => mutate()}
              disableShipmentEditing={disabled}
            />
          )}
          {auth.isStaff() && (
            <IconButton
              color="error"
              onClick={async (event) => {
                event.stopPropagation();

                try {
                  await ShipmentService.removeLineItemFromShipment(shipment.shipmentId, lineItem.lineItemId);
                  await mutate();
                } catch (error) {
                  notifications.addError(error);
                }
              }}
              disabled={disabled}
            >
              <RemoveCircleIcon />
            </IconButton>
          )}
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default LineItemRow;
