import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useTranslation } from 'react-i18next';
import ExchangeRateTable from './ExchangeRateTable';

const ExchangeRateLegend: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Accordion
      disableGutters
      slotProps={{ transition: { unmountOnExit: true } }}
      variant="unobtrusive"
    >
      <AccordionSummary
        expandIcon={<ArrowDropDownIcon />}
        sx={{ color: 'text.disabled', flexDirection: 'row-reverse' }}
      >
        {t("Today's exchange rates")}
      </AccordionSummary>
      <AccordionDetails>
        <ExchangeRateTable />
      </AccordionDetails>
    </Accordion>
  );
};

export default ExchangeRateLegend;
