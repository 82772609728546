import React from 'react';
import { TourBatchDto } from '../../../services/tour-service/tour.service';
import { Avatar, Box, Button, CircularProgress, Stack, TablePagination, Tooltip, Typography } from '@mui/material';
import { useIntParam } from '../../../hooks/useParam';
import DataTable, { TDataTableColumn } from '../../../shared/components/data-table/DataTable';
import { isToday } from '../../../shared/helper/date';
import { Lock, LockOpen } from '@mui/icons-material';
import SmartToyIcon from '@mui/icons-material/SmartToy';

import { Link } from 'react-router-dom';
import TourTypeIcon from '../TourTypeIcon';
import { TourType } from '../../../shared/backend';
import UserLabel from '../../activity/components/UserLabel';
import { CustomerService } from '../../../services/customer-service/customer.service';
import CopyToClipboardButton from '../../../shared/components/CopyToClipboardButton';
import { getShortBatchId } from '../../../shared/helper/text';

type Props = {
  batches: TourBatchDto[];
  isLoading: boolean;
  isValidating?: boolean;
};

const TourBatchTable: React.FC<Props> = ({ batches, isLoading, isValidating }) => {
  const [page, setPage] = useIntParam('page', 0);
  const [rowsPerPage, setRowsPerPage] = useIntParam('pageSize', 10);

  const customers = CustomerService.useCustomers();

  const columns: TDataTableColumn<TourBatchDto>[] = [
    {
      title: 'Direction',
      render: ({ type }) => <TourTypeIcon tourType={type} />,
    },
    {
      title: 'Notizen',
      render: ({ notes }) =>
        notes.length ? (
          <Tooltip title={notes.join('; ')}>
            <span>{notes.join('; ').slice(0, 14)}</span>
          </Tooltip>
        ) : undefined,
    },
    {
      title: 'Batch Id',
      render: ({ tourBatchId, type }) => (
        <Box sx={{ whiteSpace: 'nowrap' }}>
          <Button
            sx={{ ml: -1 }}
            variant="text"
            color={type === TourType.TOUR ? 'outbound' : 'inbound'}
            component={Link}
            to={`/customs/tour/${tourBatchId}`}
          >
            {getShortBatchId(tourBatchId)}
          </Button>
          <CopyToClipboardButton
            value={getShortBatchId(tourBatchId)}
            size="small"
          />
        </Box>
      ),
    },
    {
      title: 'Kunden',
      render: ({ customerIds }) => (
        <Tooltip
          title={
            <Box
              component="ul"
              sx={{ m: 0, p: 0, listStyleType: 'none' }}
            >
              {customers.data
                .filter(({ customerId }) => customerIds.includes(customerId))
                .sort((a, b) => (a.company.toLowerCase() > b.company.toLowerCase() ? 1 : -1))
                .map(({ customerId, company }) => (
                  <li key={customerId}>{company}</li>
                ))}
            </Box>
          }
        >
          <Typography>
            {customerIds.length === 1
              ? customers.data.find(({ customerId }) => customerId === customerIds[0])?.company.slice(0, 6) ?? 1
              : customerIds.length}
          </Typography>
        </Tooltip>
      ),
    },
    {
      title: 'Prozessgruppen',
      render: ({ processTypeLabels }) => processTypeLabels.join(', '),
    },
    {
      title: 'Pakete',
      field: 'shipmentCount',
    },
    {
      title: 'Gewicht',
      render: ({ weight }) => `${(weight / 1000).toFixed(0)} kg`,
    },
    { title: 'Zoll', render: () => '', hidden: true }, // This can only be displayed after we have cached the customs state inside the tour
    {
      title: 'Status',
      render: ({ tourCount, closedTourCount }) =>
        closedTourCount === 0 ? (
          <LockOpen />
        ) : tourCount === closedTourCount ? (
          <Lock />
        ) : (
          `${closedTourCount} / ${tourCount}`
        ),
    },
    {
      title: 'Datum',
      render: ({ date }) =>
        isToday(new Date(date)) ? new Date(date).toLocaleTimeString() : new Date(date).toLocaleString(),
    },
    {
      title: 'Ersteller',
      render: ({ createdByUserIds }) => (
        <Stack
          direction="row"
          spacing={1}
        >
          {createdByUserIds
            .sort((a, b) => (b ?? 0) - (a ?? 0))
            .map((id) =>
              id ? (
                <UserLabel
                  key={id}
                  userId={id}
                  onlyAvatar={true}
                />
              ) : (
                <Avatar key="smart">
                  <SmartToyIcon />
                </Avatar>
              ),
            )}
        </Stack>
      ),
    },
    {
      title: 'Bearbeiter',
      render: ({ assigneeUserIds }) => (
        <Stack
          direction="row"
          spacing={1}
        >
          {assigneeUserIds
            .sort((a, b) => (b ?? 0) - (a ?? 0))
            .map((id) => (
              <UserLabel
                key={id}
                userId={id}
                onlyAvatar={true}
              />
            ))}
        </Stack>
      ),
    },
  ];

  return (
    <>
      <DataTable
        page={page}
        columns={columns}
        rows={batches}
        isLoading={isLoading}
      />

      <Stack
        direction="row"
        alignItems="center"
      >
        {isValidating && (
          <>
            <CircularProgress
              size="1.2em"
              sx={{ marginX: 1, color: 'text.disabled' }}
            />
            <Typography
              variant="body2"
              color="text.disabled"
            >
              <em>Looking for new data...</em>
            </Typography>
          </>
        )}

        <Box flexGrow={1}></Box>

        <TablePagination
          component="div"
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 20, 30]}
          onRowsPerPageChange={(ev) => setRowsPerPage(parseInt(ev.target.value, 10))}
          page={page}
          count={batches.length < rowsPerPage ? page * rowsPerPage + batches.length : -1}
          onPageChange={(ev, page) => setPage(page)}
          nextIconButtonProps={{
            disabled: batches.length < rowsPerPage,
          }}
        />
      </Stack>
    </>
  );
};

export default TourBatchTable;
