import React, { PropsWithChildren, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuthentication } from './hooks/useAuthentication';

const ROLE_DIMENSION_ID = 1;

type Props = {};

const MatomoTracking: React.FC<PropsWithChildren<Props>> = ({ children }) => {
  const location = useLocation();
  const auth = useAuthentication();

  const url = `${location.pathname}${location.search}${location.hash}`;
  const { role } = auth;

  useEffect(() => {
    console.log('New location', url, window.document.title);

    window._paq.push(['setCustomUrl', url]);
    window._paq.push(['trackPageView']);

    if (url.startsWith('/logout')) {
      window._paq.push(['deleteCustomDimension', ROLE_DIMENSION_ID]);
      window._paq.push(['resetUserId']);
      window._paq.push(['appendToTrackingUrl', 'new_visit=1']);
      window._paq.push(['trackPageView']);

      window._paq.push(['appendToTrackingUrl', '']);
    }
  }, [url]);

  useEffect(() => {
    if (role) {
      window._paq.push(['setCustomDimension', ROLE_DIMENSION_ID, role]);
    }
  }, [role]);

  return children;
};

export default MatomoTracking;
