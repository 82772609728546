import React, { useEffect, useState } from 'react';
import { TourBatchDto, TourService } from '../../../services/tour-service/tour.service';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import DataTable, { TDataTableAction, TDataTableColumn } from '../../../shared/components/data-table/DataTable';
import { CustomerService } from '../../../services/customer-service/customer.service';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { TourType } from '../../../shared/backend';
import { useNavigate } from 'react-router-dom';
import { useNotifications } from '../../../hooks/useNotifications';
import { isToday } from '../../../shared/helper/date';
import { Lock, LockOpen } from '@mui/icons-material';
import TourStateOptionSelector, { TourStateOption, getTourStateFromTourStateOption } from '../TourStateOptionSelector';
import { CustomerFilter } from '../../../shared/components/customer-filter/CustomerFilter';
import { getShortBatchId } from '../../../shared/helper/text';
import { ProcessTypeDto } from '../../../services/process-type-service/process-type.service';

type Props = {
  open: boolean;
  onClose: () => void;
  batch: TourBatchDto;
  processTypes: ProcessTypeDto[];
};

const MergeDialog: React.FC<Props> = ({ open, batch, processTypes, onClose }) => {
  const notifications = useNotifications();
  const navigateTo = useNavigate();

  const [isCreating, setIsCreating] = useState(false);
  const [selectedBatchIds, setSelectedBatchIds] = useState<string[]>([]);
  const [limit, setLimit] = useState(10);
  const [tourStateOption, setTourStateOption] = useState<TourStateOption>(TourStateOption.OPEN);
  const [customerIds, setCustomerIds] = useState<number[]>([]);

  const tagField = batch.type === TourType.TOUR ? 'outboundTag' : 'inboundTag';
  const processTypeTag = processTypes.find((pt) => pt.processTypeId === batch.processTypeIds[0])?.[tagField];

  const customers = CustomerService.useCustomers();
  const candidates = TourService.useTourBatches({
    limit,
    tourType: batch.type,
    dispatchCountry: batch.dispatchCountry,
    destinationCountry: batch.destinationCountry,
    tourState: getTourStateFromTourStateOption(tourStateOption),
    customerIds: customerIds.length ? customerIds : undefined,
    processTypeTag,
  });

  useEffect(() => {
    setSelectedBatchIds([]);
  }, [batch.tourBatchId]);

  const columns: TDataTableColumn<TourBatchDto>[] = [
    {
      title: 'Batch Id',
      render: ({ tourBatchId }) => (
        <Typography color={batch.type === TourType.TOUR ? 'outbound.main' : 'inbound.main'}>
          {getShortBatchId(tourBatchId)}
        </Typography>
      ),
    },
    {
      title: 'Notizen',
      render: ({ notes }) =>
        notes.length ? (
          <Tooltip title={notes.join('; ')}>
            <span>{notes.join('; ').slice(0, 14)}</span>
          </Tooltip>
        ) : undefined,
    },
    {
      title: 'Kunden',
      render: ({ customerIds }) =>
        customers.isLoading
          ? customerIds.length
          : customers.data
              .filter(({ customerId }) => customerIds.includes(customerId))
              .sort((a, b) => (a.company.toLowerCase() > b.company.toLowerCase() ? 1 : -1))
              .map(({ company }) => company)
              .join(', '),
    },
    {
      title: 'Prozessgruppen',
      render: ({ processTypeLabels }) => processTypeLabels.join(', '),
    },
    {
      title: 'Datum',
      render: ({ date }) =>
        isToday(new Date(date)) ? new Date(date).toLocaleTimeString() : new Date(date).toLocaleString(),
    },
    {
      title: 'Status',
      render: ({ tourCount, closedTourCount }) =>
        closedTourCount === 0 ? (
          <LockOpen />
        ) : tourCount === closedTourCount ? (
          <Lock />
        ) : (
          `${closedTourCount} / ${tourCount}`
        ),
    },
  ];

  const actions: TDataTableAction<TourBatchDto>[] = [
    {
      icon: ({ tourBatchId }) =>
        selectedBatchIds.includes(tourBatchId) || tourBatchId === batch.tourBatchId ? (
          <CheckBoxIcon color={tourBatchId === batch.tourBatchId ? 'disabled' : 'secondary'} />
        ) : (
          <CheckBoxOutlineBlankIcon />
        ),
      onClick: ({ tourBatchId }) =>
        selectedBatchIds.includes(tourBatchId)
          ? setSelectedBatchIds(selectedBatchIds.filter((id) => id !== tourBatchId))
          : setSelectedBatchIds([...selectedBatchIds, tourBatchId]),
      tooltip: '',
      disabled: ({ tourBatchId }) => tourBatchId === batch.tourBatchId || isCreating,
    },
  ];

  return (
    <Dialog
      open={open}
      onClose={() => (isCreating ? undefined : onClose())}
      maxWidth="md"
    >
      <DialogTitle>Zwischentouren zusammenlegen mit {getShortBatchId(batch.tourBatchId)}</DialogTitle>

      <DialogContent>
        <DialogContentText>
          Hier können mehrere Zwischentouren zu einer neuen zusammengefasst werden. Die Änderung kann nicht mehr
          rückgängig gemacht werden.
        </DialogContentText>

        <Stack
          direction="row"
          spacing={1}
          sx={{ my: 3 }}
          justifyContent="flex-end"
        >
          <CustomerFilter
            customers={customers.data}
            onChange={(ids) => setCustomerIds(ids)}
            selectedCustomerIds={customerIds}
            multiple={true}
          />

          <TourStateOptionSelector
            tourStateOption={tourStateOption}
            onChange={(value) => setTourStateOption(value)}
          />
        </Stack>

        <DataTable
          columns={columns}
          rows={
            !!candidates.data.find(({ tourBatchId }) => tourBatchId === batch.tourBatchId)
              ? candidates.data
              : [batch, ...candidates.data]
          }
          actions={actions}
          isLoading={candidates.isLoading}
        />

        <Stack
          direction="row"
          justifyContent="center"
          sx={{ my: 3 }}
        >
          <Button
            size="small"
            disabled={candidates.isLoading}
            onClick={() => setLimit(limit + 10)}
          >
            Weitere laden
          </Button>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => onClose()}
          color="secondary"
          disabled={isCreating}
        >
          Abbrechen
        </Button>

        <Box flexGrow={1}></Box>

        <Button
          disabled={!selectedBatchIds.length || isCreating}
          variant="contained"
          startIcon={
            isCreating ? (
              <CircularProgress
                size="1em"
                color="inherit"
              />
            ) : undefined
          }
          onClick={() => {
            setIsCreating(true);

            TourService.mergeTourBatches([batch.tourBatchId, ...selectedBatchIds])
              .then((tourBatchId) => {
                notifications.addSuccess(`Neue Zwischentour ${getShortBatchId(tourBatchId)} erstellt.`);

                setTimeout(() => navigateTo(`/customs/tour/${tourBatchId}`), 3000);
              })
              .catch((error) => {
                notifications.addError(error);

                setIsCreating(false);
              });
          }}
        >
          {selectedBatchIds.length + 1} Touren zusammenlegen
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MergeDialog;
