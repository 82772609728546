import axios from 'axios';
import useSWR from 'swr';
import client from '../../shared/client';
import { backendUri } from '../../shared/helper/env/helper';

export interface IOutdatedStatus {
  process: number;
  isRunning: boolean;
  lastRun: string;
}

export interface IImporterHealthStatus {
  status: 'up' | 'down';
  article: IOutdatedStatus[];
  dailyArticle: IOutdatedStatus[];
  order: IOutdatedStatus[];
}

export interface ICategoryHealthStatus {
  status: 'up' | 'down';
  categories: IOutdatedStatus[];
}

export interface ICommonHealthStatus {
  status: 'up' | 'down';
  message?: string;
}
export interface IFtpServerHealthStatus extends ICommonHealthStatus {
  availableDiskSpaceInPercent?: number;
}

export interface IHealthStatus {
  status: 'ok' | 'error';
  info: Record<string, ICommonHealthStatus> & {
    importers?: IImporterHealthStatus;
    'ftp-server': IFtpServerHealthStatus;
    categories: ICategoryHealthStatus;
  };
  error: Record<string, ICommonHealthStatus> & {
    importers?: IImporterHealthStatus;
    'ftp-server': IFtpServerHealthStatus;
    categories: ICategoryHealthStatus;
  };
  details: Record<string, ICommonHealthStatus> & {
    importers?: IImporterHealthStatus;
    'ftp-server': IFtpServerHealthStatus;
    categories: ICategoryHealthStatus;
  };
}

const SYSTEM_TRANSLATION = {
  database: 'Datenbank',
  'exporto-logistic-ms-v2': 'Logistic MS',
  'exporto-queue-manager': 'Queue Manager',
  'exporto-frontend': 'Frontend',
  'exporto-return-frontend': 'Return Frontend',
  memory_heap: 'Speicher',
  storage: 'Festplatte',
  importers: 'Importer',
  categories: 'Category',
  'ftp-server': 'FTP Server',
  'exporto-api': 'Exporto API',
  'exchange-rates-for-today': `Today's exchange rates`,
};

export const getSystemLabel = (system: string): string =>
  system in SYSTEM_TRANSLATION ? SYSTEM_TRANSLATION[system as keyof typeof SYSTEM_TRANSLATION] : system;

const healthFetcher = (url: string) => {
  return client
    .get(url, {
      baseURL: backendUri,
    })
    .then((res) => res.data)
    .catch((error) => {
      if (axios.isAxiosError(error) && error.response?.status === 503) {
        return error.response?.data;
      }

      return { status: 'error', info: {}, error: {}, details: {} };
    });
};

export const useHealthStatus = (force: boolean = false) => {
  const url = new URL(`${backendUri}/health/system`);

  const { data, error, mutate } = useSWR<IHealthStatus>(
    process.env.NODE_ENV === 'development' && !force ? null : url.href,
    healthFetcher,
    {
      refreshInterval: 5 * 60 * 1000,
    },
  );

  return {
    data,
    error,
    isLoading: !!url && !error && typeof data === 'undefined',
    isError: !!error,
    mutate,
  };
};
