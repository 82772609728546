import { Divider, MenuItem, Select, SelectProps } from '@mui/material';
import React from 'react';
import { countryToFlag } from '../helper/country';
import { IsoCountryCode } from '../backend';
import { useTranslation } from 'react-i18next';

const defaultCommonCountryCodes = [
  IsoCountryCode.DE,
  IsoCountryCode.CH,
  IsoCountryCode.GB,
  IsoCountryCode.FR,
  IsoCountryCode.AT,
];

const SelectCountryCode = <T,>({
  commonCountryCodes,
  withUnknownCustomsCountryCode,
  ...props
}: SelectProps<T> & { commonCountryCodes?: IsoCountryCode[]; withUnknownCustomsCountryCode?: boolean }) => {
  const { t } = useTranslation('CRM');
  const commonCodes = commonCountryCodes ?? defaultCommonCountryCodes;

  return (
    <Select {...props}>
      <MenuItem value="">
        <em>{t('Undefined')}</em>
      </MenuItem>
      {!!withUnknownCustomsCountryCode && (
        <MenuItem value="QU">
          <em>{t('Unknown')}</em>
        </MenuItem>
      )}
      {commonCodes.map((id) => (
        <MenuItem
          key={id}
          value={id}
        >
          <span>{countryToFlag(id)}</span>&nbsp;
          {id}
        </MenuItem>
      ))}
      <Divider />
      {Object.entries(IsoCountryCode)
        .sort((a, b) => (a[1] < b[1] ? -1 : 1))
        .filter((entry) => !commonCodes.includes(entry[0] as IsoCountryCode))
        .map(([id, label]) => (
          <MenuItem
            key={id}
            value={id}
          >
            <span>{countryToFlag(id)}</span>&nbsp;
            {label}
          </MenuItem>
        ))}
    </Select>
  );
};

export default SelectCountryCode;
