import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Stack,
  TablePagination,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import AddBox from '@mui/icons-material/AddBox';
import Edit from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/FilterAlt';
import ClearIcon from '@mui/icons-material/Clear';
import React, { Fragment, FunctionComponent } from 'react';
import InfoToast from '../../../../shared/components/info-toast/InfoToast';
import DataTable, { TDataTableAction, TDataTableColumn } from '../../../../shared/components/data-table/DataTable';
import { useNavigate } from 'react-router-dom';
import { usePagination } from '../../../../shared/hooks/page';
import BlockIcon from '@mui/icons-material/Block';
import { CustomerDto, CustomerService } from '../../../../services/customer-service/customer.service';
import { ProcessService } from '../../../../services/process-service/process.service';
import { useEnumParam, useStringParam } from '../../../../hooks/useParam';
import { useTranslation } from 'react-i18next';

enum SortBy {
  CustomerId = 'customerId',
  CreatedAt = 'process.createdAt',
}

export const CustomerTable: FunctionComponent<{}> = () => {
  const { t } = useTranslation('CRM');
  const navigate = useNavigate();

  //==========================================================

  //Table =======================================================
  const [companyFilter, setCompanyFilter] = useStringParam('q', '');
  const [sortBy, setSortBy] = useEnumParam('sortBy', SortBy, SortBy.CustomerId);
  const [infoMsg, setInfoMsg] = React.useState<string>('');
  const processes = ProcessService.useProcesses();

  const { page, pageSize: rowsPerPage, setPage, setPageSize: setRowsPerPage } = usePagination();

  const customers = CustomerService.useCustomers();

  const columns: TDataTableColumn<CustomerDto>[] = [
    { title: t('Customer Id'), field: 'customerId' },
    { title: t('Company'), field: 'company' },
    { title: t('Note'), field: 'note' },
    { title: t('E-mail'), field: 'email' },
    {
      title: t('Processes'),
      render: (customer) => (
        <>
          {processes.data
            .filter((process) => process.customerId === customer.customerId)
            .map((process, index, processes) => (
              <Box
                component="span"
                key={process.processId}
              >
                <Box
                  sx={(theme) => (process.blocked ? { color: theme.palette.error.main } : {})}
                  component="span"
                >
                  {process.blocked && (
                    <BlockIcon
                      color="error"
                      sx={{ fontSize: '1em', verticalAlign: 'text-top' }}
                    />
                  )}{' '}
                  {process.processId}
                </Box>
                {index < processes.length - 1 ? ', ' : ''}
              </Box>
            ))}
        </>
      ),
    },
    {
      title: t('Shop systems'),
      render: (customer) =>
        processes.data
          .filter((process) => process.customerId === customer.customerId)
          .map((process) => process.shopSystem)
          .join(', '),
    },
  ];

  const actions: TDataTableAction<CustomerDto>[] = [
    {
      icon: () => <Edit />,
      tooltip: t('Edit'),
      onClick: (customer) => {
        navigate(`/crm/customers/${customer.customerId}`);
      },
    },
  ];

  const customerPredicate = (customer: CustomerDto): boolean => {
    const matchingProcesses = processes.data.filter(
      (process) => process.processId.toString() === companyFilter || process.shopSystem === companyFilter,
    );
    const regex = new RegExp(companyFilter, 'i');

    return (
      regex.test(customer.company) || !!matchingProcesses.find((process) => process.customerId === customer.customerId)
    );
  };

  const rows = companyFilter && customers.data ? customers.data?.filter(customerPredicate) : customers.data ?? [];

  const byCustomerId = (a: CustomerDto, b: CustomerDto): number => {
    return a.customerId - b.customerId;
  };

  const byProcessCreatedAt = (a: CustomerDto, b: CustomerDto): number => {
    const processA = processes.data.find((process) => process.customerId === a.customerId);
    const processB = processes.data.find((process) => process.customerId === b.customerId);

    if (!processA && !processB) {
      return 0;
    }

    if (!processB) {
      return 1;
    }

    if (!processA) {
      return -1;
    }

    return new Date(processB.createdAt).getTime() - new Date(processA.createdAt).getTime();
  };

  const compareFunctions = {
    [SortBy.CustomerId]: byCustomerId,
    [SortBy.CreatedAt]: byProcessCreatedAt,
  };

  const sortedRows = [...rows].sort(compareFunctions[sortBy]);

  return (
    <Fragment>
      <InfoToast
        infoMsg={infoMsg}
        open={infoMsg !== ''}
        onClose={() => setInfoMsg('')}
      ></InfoToast>

      <Paper>
        <Toolbar>
          <Typography variant="h5">{t('Customers', { ns: 'shared' })}</Typography>

          <Box flexGrow={1}></Box>

          <Box>
            <Stack
              direction="row"
              spacing={2}
            >
              <TextField
                size="small"
                margin="none"
                value={companyFilter}
                placeholder={t('Company Filter')}
                onChange={(ev) => {
                  setCompanyFilter(ev.target.value);
                  setPage(0);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {companyFilter ? (
                        <IconButton
                          onClick={() => setCompanyFilter('')}
                          edge="end"
                        >
                          <ClearIcon />
                        </IconButton>
                      ) : (
                        <SearchIcon color="disabled" />
                      )}
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                size="small"
                select
                value={sortBy}
                onChange={(ev) => setSortBy(ev.target.value as SortBy)}
              >
                <MenuItem value={SortBy.CustomerId}>{t('Customer no.')}</MenuItem>
                <MenuItem value={SortBy.CreatedAt}>{t('Process creation date')}</MenuItem>
              </TextField>

              <Button
                color="secondary"
                size="small"
                onClick={() => navigate(`/crm/customers/new`)}
                startIcon={<AddBox />}
                variant="outlined"
              >
                {t('Create customer')}
              </Button>
            </Stack>
          </Box>
        </Toolbar>

        <DataTable
          columns={columns}
          actions={actions}
          page={page}
          rows={sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
        />

        <TablePagination
          component="div"
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 20, 30]}
          onRowsPerPageChange={(ev) => setRowsPerPage(parseInt(ev.target.value, 10))}
          page={page}
          count={sortedRows.length}
          onPageChange={(ev, page) => setPage(page)}
          showLastButton={true}
          showFirstButton={true}
        />
      </Paper>
    </Fragment>
  );
};
