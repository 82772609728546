import React from 'react';
import ClearIcon from '@mui/icons-material/Clear';

import { FormControl, IconButton, InputAdornment, MenuItem, SxProps, TextField, Theme } from '@mui/material';
import { JobStatus } from '../../../shared/backend';
import { useTranslation } from 'react-i18next';

export interface ISelectJobStateProps {
  sx?: SxProps<Theme>;
  selected: JobStatus[];
  onSelect: (jobStates: JobStatus[]) => void;
}

const jobStates = ['completed', 'waiting', 'active', 'delayed', 'failed', 'paused'] as const;
type JobState = (typeof jobStates)[number];

const SelectJobState = (props: ISelectJobStateProps) => {
  const { t } = useTranslation('jobs');
  const jobStateLabel: Record<JobState, string> = {
    completed: t('Completed'),
    waiting: t('Waiting'),
    active: t('Active'),
    delayed: t('Delayed'),
    failed: t('Failed'),
    paused: t('Paused'),
  };
  return (
    <FormControl sx={props.sx}>
      <TextField
        size="small"
        select
        label={t('State')}
        value={props.selected}
        SelectProps={{
          multiple: true,
          startAdornment: !!props.selected.length && (
            <InputAdornment position="start">
              <IconButton
                onClick={() => props.onSelect([])}
                edge="start"
              >
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        onChange={(ev) => props.onSelect(ev.target.value as unknown as JobStatus[])}
      >
        {jobStates.map((jobState) => (
          <MenuItem
            key={jobState}
            value={jobStateLabel[jobState]}
          >
            {jobStateLabel[jobState]}
          </MenuItem>
        ))}
      </TextField>
    </FormControl>
  );
};

export default SelectJobState;
