import useSWR, { KeyedMutator } from 'swr';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { backendUri } from './helper/env/helper';
import { Backend } from './backend';

const client = axios.create({
  baseURL: backendUri?.endsWith('/') ? backendUri : `${backendUri}/`,
  withCredentials: true,
});

export default client;

/**
 * Should be used to create interfaces for every schema. The advantage of
 * interfaces is that VSC shows the interface name on hover, instead of the type
 * definition.
 */
export type SchemaType<K extends keyof Backend.components['schemas']> = Backend.components['schemas'][K];

export interface IUseObjectResponse<Data extends object, Error = unknown> {
  data: Data | null;
  error: Error | undefined;
  isLoading: boolean;
  isError: boolean;
  mutate: KeyedMutator<Data>;
}

export interface IUseArrayResponse<Data extends object, Error = unknown> {
  data: Data[];
  error: Error | undefined;
  isLoading: boolean;
  isValidating?: boolean;
  isError: boolean;
  mutate: KeyedMutator<Data[]>;
}

type TUrlQueryParam = string | string[] | number | number[] | boolean | boolean[] | object | object[] | null;

// Api client types and wrapper for use with https://www.npmjs.com/package/openapi-typescript

// This type returns only paths with the given method
type ExtractMethod<Method extends 'get' | 'post' | 'put' | 'delete', Type extends Record<string, unknown>> = {
  [Property in keyof Type as Type[Property] extends { [k in Method]: unknown } ? Property : never]: Type[Property];
};

type TOperation = {
  parameters?: { query?: unknown; path?: unknown; header?: unknown };
  requestBody?: { content: { 'application/json': unknown } };
  responses: Record<number, { content: { 'application/json': unknown } }>;
};
type TPath = string;
type TPaths = { [path: TPath]: Record<string, TOperation> };

// Generic response type
type TResponse<
  P extends TPaths,
  Method extends 'get' | 'post' | 'put' | 'delete',
  StatusCode extends keyof P[U][Method]['responses'],
  U extends keyof ExtractMethod<Method, P>,
> = P[U][Method]['responses'][StatusCode] extends { content: { 'application/json': unknown } }
  ? P[U][Method]['responses'][StatusCode]['content']['application/json']
  : never;

// Response types for our api for every method
export type TApiGetResponse<
  U extends keyof ExtractMethod<'get', P>,
  StatusCode extends keyof P[U]['get']['responses'] = 200,
  P extends TPaths = Backend.paths,
> = TResponse<P, 'get', StatusCode, U>;
export type TApiPostResponse<
  U extends keyof ExtractMethod<'post', P>,
  StatusCode extends keyof P[U]['post']['responses'] = 201,
  P extends TPaths = Backend.paths,
> = TResponse<P, 'post', StatusCode, U>;
export type TApiPutResponse<
  U extends keyof ExtractMethod<'put', P>,
  StatusCode extends keyof P[U]['put']['responses'] = 200,
  P extends TPaths = Backend.paths,
> = TResponse<P, 'put', StatusCode, U>;
export type TApiDeleteResponse<
  U extends keyof ExtractMethod<'delete', P>,
  StatusCode extends keyof P[U]['delete']['responses'] = 200,
  P extends TPaths = Backend.paths,
> = TResponse<P, 'delete', StatusCode, U>;

// Generic request body
type TRequestBody<
  P extends TPaths,
  Method extends 'post' | 'put' | 'delete',
  U extends keyof ExtractMethod<Method, P>,
> = P[U][Method]['requestBody'] extends { content: unknown }
  ? P[U][Method]['requestBody']['content']['application/json']
  : never;

// Request types for our api for every method
export type TApiPostRequestBody<
  U extends keyof ExtractMethod<'post', P>,
  P extends TPaths = Backend.paths,
> = TRequestBody<P, 'post', U>;
export type TApiPutRequestBody<
  U extends keyof ExtractMethod<'put', P>,
  P extends TPaths = Backend.paths,
> = TRequestBody<P, 'put', U>;
export type TApiDeleteRequestBody<
  U extends keyof ExtractMethod<'delete', P>,
  P extends TPaths = Backend.paths,
> = TRequestBody<P, 'delete', U>;

class API<P extends TPaths> {
  public readonly get = <
    U extends keyof ExtractMethod<'get', P>,
    T = TApiGetResponse<U, 200, P>,
    R = AxiosResponse<T>,
    D = never,
  >(
    url: string,
    config?: AxiosRequestConfig<D>,
  ): Promise<R> => {
    if (!!config?.params && typeof config.params === 'object') {
      const obj: Record<string, TUrlQueryParam> = config.params;
      for (const key of Object.keys(config?.params)) {
        const value = obj[key];
        if (value !== null && value !== undefined && !(Array.isArray(value) && value.length === 0)) {
          obj[key] = this.stringifyUrlParam(value);
        }
      }
      config.params = obj;
    }
    return client.get(url, config);
  };

  public readonly post = <
    U extends keyof ExtractMethod<'post', P>,
    T = TApiPostResponse<U, 201, P>,
    R = AxiosResponse<T>,
    D = TApiPostRequestBody<U, P>,
  >(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>,
  ): Promise<R> => client.post(url, data, config);

  public readonly put = <
    U extends keyof ExtractMethod<'put', P>,
    T = TApiPutResponse<U, 200, P>,
    R = AxiosResponse<T>,
    D = TApiPutRequestBody<U, P>,
  >(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>,
  ): Promise<R> => client.put(url, data, config);

  public readonly delete = <
    U extends keyof ExtractMethod<'delete', P>,
    T = TApiDeleteResponse<U, 200, P>,
    R = AxiosResponse<T>,
    D = TApiDeleteRequestBody<U, P>,
  >(
    url: string,
    config?: AxiosRequestConfig<D>,
  ): Promise<R> => client.delete(url, config);

  public readonly useApi = <U extends keyof ExtractMethod<'get', P>>(
    ...[url, params]: P[U]['get']['parameters'] extends { path: Record<string, string | number> }
      ? P[U]['get']['parameters'] extends { query?: Record<string, TUrlQueryParam> }
        ? [
            url: ({ key: string & U } & P[U]['get']['parameters']['path']) | null,
            params: P[U]['get']['parameters']['query'],
          ]
        : [url: ({ key: string & U } & P[U]['get']['parameters']['path']) | null]
      : P[U]['get']['parameters'] extends { query?: Record<string, TUrlQueryParam> }
        ? [url: (string & U) | null, params: P[U]['get']['parameters']['query']]
        : [url: (string & U) | null]
  ) => {
    const searchParams = new URLSearchParams();

    let key: (string & U) | null;

    if (typeof url === 'object' && url) {
      const { key: keyParam, ...path } = url;

      key = keyParam;

      for (const variable in path) {
        const value = path[variable];

        key = key.replaceAll(`{${variable}}`, encodeURIComponent(value.toString())) as string & U;
      }
    } else {
      key = url;
    }

    if (params) {
      Object.entries(params).forEach(([key, value]) => {
        if (value !== undefined && !(Array.isArray(value) && value.length === 0)) {
          searchParams.set(key, this.stringifyUrlParam(value));
        }
      });
    }

    return useSWR<P[U]['get']['responses'][200]['content']['application/json']>(
      key ? key + '?' + searchParams.toString() : key,
    );
  };

  private readonly stringifyUrlParam = (value: TUrlQueryParam): string => {
    if (Array.isArray(value)) {
      return value.map((entry) => this.stringifyUrlParam(entry)).join(',');
    }
    if (typeof value === 'object') {
      return JSON.stringify(value);
    }
    return value.toString();
  };
}

export const api = new API<Backend.paths>();

export const useApi = api.useApi;
