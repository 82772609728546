import { IUseArrayResponse, SchemaType, useApi } from '../../shared/client';

export interface ExchangeRateDto extends SchemaType<'ExchangeRateDto'> {}

export namespace ExchangeRateService {
  export const useExchangeRates = (): IUseArrayResponse<ExchangeRateDto> & { isValidating: boolean } => {
    const { data, error, mutate, isLoading, isValidating } = useApi('/exchange-rate');

    return {
      data: data ?? [],
      error,
      isLoading,
      isValidating,
      isError: !!error,
      mutate,
    };
  };
}
