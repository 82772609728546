import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React from 'react';

import { ORIGIN_AREAS } from '../../../../../../../services/process-service/process.service';
import { useTranslation } from 'react-i18next';

type Props = {
  disabled: boolean;
  error: boolean;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | SelectChangeEvent<string>) => void;
  onBlur?: (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
};

export const SelectFederalState = (props: Props) => {
  const { t } = useTranslation('CRM');
  return (
    <Select
      disabled={props.disabled}
      error={props.error}
      id={'federalState'}
      fullWidth
      value={props.value}
      onChange={props.onChange}
      onBlur={(event) => {
        if (props.onBlur) {
          props.onBlur(event);
        }
      }}
      variant="outlined"
    >
      <MenuItem value="">
        <em>{t('Undefined')}</em>
      </MenuItem>
      {Object.entries(ORIGIN_AREAS)
        .sort((a, b) => (a[1] < b[1] ? -1 : 1))
        .map(([id, label]) => (
          <MenuItem
            key={id}
            value={id}
          >
            {label} ({id})
          </MenuItem>
        ))}
    </Select>
  );
};
