import {
  initializeFaro,
  getWebInstrumentations,
  ReactIntegration,
  createReactRouterV6Options,
  faro,
  FaroRoutes,
} from '@grafana/faro-react';
import React, { PropsWithChildren, useEffect } from 'react';
import { createRoutesFromChildren, matchRoutes, Routes, useLocation, useNavigationType } from 'react-router-dom';
import { useAuthentication } from './hooks/useAuthentication';
import { Config } from './shared/helper/env/helper';

if (Config.TELEMETRY.ENABLED) {
  initializeFaro({
    url: Config.TELEMETRY.COLLECT_URL,
    apiKey: Config.TELEMETRY.API_KEY,

    trackWebVitalsAttribution: true,

    batching: {
      // default is sending data every 250ms, which is too much.
      sendTimeout: 25000,
    },
    app: {
      name: 'exporto-frontend',
      environment: Config.ENV,
    },
    instrumentations: [
      // Load the default Web instrumentations
      ...getWebInstrumentations({
        // lets keep it off for now, because we don't know if we log sensitive information
        captureConsole: false,
      }),

      new ReactIntegration({
        router: createReactRouterV6Options({
          createRoutesFromChildren,
          matchRoutes,
          Routes,
          useLocation,
          useNavigationType,
        }),
      }),
    ],
  });
}

type Props = {};

const Faro: React.FC<PropsWithChildren<Props>> = ({ children }) => {
  const auth = useAuthentication();

  useEffect(() => {
    faro.api.setUser({ attributes: { role: auth.role ?? 'anonymous' } });
  }, [auth.role]);

  return <FaroRoutes>{children}</FaroRoutes>;
};

export default Faro;
