import { ExportoEnv } from '../globals';

export const backendUri: string | undefined = process.env.REACT_APP_API;
export const returnPortalUrl: string | undefined = process.env.REACT_APP_RETOUREN_CONSUMER_FRONTEND_URI;

export class Config {
  private static getNumber(value: string | undefined, defaultValue: number): number {
    const parsedValue = parseInt(value ?? '', 10);

    return isNaN(parsedValue) ? defaultValue : parsedValue;
  }

  public static readonly PUBLIC_URL = process.env.PUBLIC_URL || '';

  public static readonly ENV = process.env.REACT_APP_ENV || ExportoEnv.development;

  public static readonly TELEMETRY = {
    ENABLED: process.env.REACT_APP_TELEMETRY_ENABLED === 'true' && !!process.env.REACT_APP_TELEMETRY_COLLECT_URL,
    COLLECT_URL: process.env.REACT_APP_TELEMETRY_COLLECT_URL,
    API_KEY: process.env.REACT_APP_TELEMETRY_API_KEY,
  };

  public static readonly MATOMO = {
    ENABLED: !!process.env.REACT_APP_MATOMO_SITE_ID && process.env.REACT_APP_MATOMO_TRACKER_URL,
    SITE_ID: Config.getNumber(process.env.REACT_APP_MATOMO_SITE_ID, 0),
    TRACKER_URL: process.env.REACT_APP_MATOMO_TRACKER_URL,
  };
}
