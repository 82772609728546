import {
  Checkbox,
  Chip,
  FilledInputProps,
  IconButton,
  InputAdornment,
  InputProps,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  MenuItem,
  OutlinedInputProps,
  Stack,
  SxProps,
  TextField,
  Tooltip,
} from '@mui/material';
import React, { useRef, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

interface IValue<T> {
  id: T;
  label: string;
  icon?: React.ReactNode;
}

export type SelectWithFilterProps<T> = {
  values: IValue<T>[];
  selectedValueIds: T[];
  onChange: (ids: T[]) => void;
  multiple?: boolean;
  label?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  disableEmptyValue?: boolean;
  name?: string;
  required?: boolean;
  size?: 'small' | 'medium';
  sx?: SxProps;
  InputProps?: Partial<FilledInputProps> | Partial<OutlinedInputProps> | Partial<InputProps>;
};

const SelectWithFilter = <T extends string | number>({
  values,
  selectedValueIds,
  onChange,
  multiple,
  label,
  fullWidth,
  disabled,
  disableEmptyValue,
  name,
  required,
  size,
  sx,
  InputProps,
}: SelectWithFilterProps<T>) => {
  const [showSelect, setShowSelect] = useState(false);
  const [searchText, setSearchText] = useState('');
  const searchInputRef = useRef<HTMLInputElement>();

  const options = searchText.trim()
    ? values.filter(({ label }) => label.toLowerCase().includes(searchText.toLowerCase()))
    : values;

  const renderMultipleValues = () => {
    const numberOfLabels = 2;
    const selectedValues = selectedValueIds
      .map((id) => values.find((value) => value.id === id))
      .filter((value): value is IValue<T> => !!value);

    return (
      <Stack
        direction="row"
        spacing={1}
      >
        {selectedValues.slice(0, numberOfLabels).map((value) => (
          <Chip
            size="small"
            color={disabled ? 'default' : 'secondary'}
            key={value.id}
            label={value.label}
          />
        ))}
        {selectedValues.length > numberOfLabels && (
          <Tooltip
            title={selectedValues
              .slice(2)
              .map(({ label }) => label)
              .join(', ')}
          >
            <Chip
              size="small"
              color="default"
              label={`${selectedValues.length - numberOfLabels} weitere`}
            />
          </Tooltip>
        )}
      </Stack>
    );
  };

  const renderSingleValue = () => {
    const selectedValue = values.find((value) => value.id === selectedValueIds[0]);

    return <>{selectedValue?.label}</>;
  };

  return (
    <TextField
      select
      name={name}
      required={required}
      fullWidth={fullWidth}
      size={size}
      sx={sx}
      variant="outlined"
      label={label}
      onChange={(event) =>
        onChange(Array.isArray(event.target.value) ? (event.target.value as T[]) : [event.target.value as T])
      }
      InputProps={InputProps}
      SelectProps={{
        multiple,
        open: showSelect,
        onOpen: () => setShowSelect(true),
        onClose: () => {
          setShowSelect(false);
          setSearchText('');
        },
        startAdornment:
          selectedValueIds.filter((id) => !!id || typeof id === 'number').length > 0 && !disableEmptyValue ? (
            <InputAdornment position="start">
              <IconButton
                onClick={() => onChange([])}
                edge="start"
                size="small"
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            </InputAdornment>
          ) : undefined,
        renderValue: multiple ? renderMultipleValues : renderSingleValue,
        MenuProps: {
          autoFocus: false,
        },
      }}
      disabled={disabled || !values.length}
      value={multiple ? selectedValueIds : selectedValueIds[0] ?? ''}
      onAnimationEnd={() => searchInputRef.current?.focus()}
    >
      <ListSubheader
        disableGutters
        sx={{ px: 1 }}
      >
        <TextField
          inputRef={searchInputRef}
          size="small"
          autoFocus
          placeholder="Search"
          fullWidth
          value={searchText}
          inputProps={{ autoFocus: true }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon
                  sx={{ ml: -1 }}
                  color="disabled"
                />
              </InputAdornment>
            ),
            endAdornment: searchText.trim() ? (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setSearchText('')}
                  edge="end"
                  size="small"
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              </InputAdornment>
            ) : undefined,
          }}
          onChange={(ev) => setSearchText(ev.target.value)}
          onKeyDown={(ev) => {
            if (ev.key !== 'Escape') {
              ev.stopPropagation();
            }

            if (ev.key === 'Enter' && options.length === 1) {
              const optionId = options[0].id;

              if (selectedValueIds.includes(optionId)) {
                onChange(selectedValueIds.filter((id) => id !== optionId));
              } else {
                if (multiple) {
                  onChange([...selectedValueIds, optionId]);
                } else {
                  onChange([optionId]);

                  setShowSelect(false);
                }
              }
              setSearchText('');
            }
          }}
        />
      </ListSubheader>

      {selectedValueIds
        .filter((valueId) => !values.find(({ id }) => id === valueId))
        .map((id) => (
          <MenuItem
            key={id}
            value={id}
          >
            {id}
          </MenuItem>
        ))}
      {options.map(({ id, label, icon }) => (
        <MenuItem
          key={id}
          value={id}
          sx={multiple ? { pl: 0 } : undefined}
        >
          {multiple && (
            <ListItemIcon>
              <Checkbox checked={selectedValueIds.includes(id)} />
            </ListItemIcon>
          )}
          {icon && !multiple && <ListItemIcon>{icon}</ListItemIcon>}
          <ListItemText primary={label} />
        </MenuItem>
      ))}
    </TextField>
  );
};

export default SelectWithFilter;
