import { Box, CircularProgress, FormControl, Grid, InputLabel, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import { useNotifications } from '../../../../hooks/useNotifications';
import { LoadingButton } from '@mui/lab';
import SelectCountryCode from '../../../../shared/components/SelectCountryCode';
import { FiscalRepresentationDto, ProcessService } from '../../../../services/process-service/process.service';
import { Controller, useForm } from 'react-hook-form';
import FormTextField from '../../../../shared/components/react-hook-form/FormTextField';
import { useTranslation } from 'react-i18next';

type Props = {
  processId: number;
};

const FiscalRepresentation: React.FC<Props> = ({ processId }) => {
  const { t } = useTranslation('CRM');

  const notifications = useNotifications();
  const fiscalRepresentation = ProcessService.useFiscalRepresentation(processId);

  const {
    formState: { isDirty, isValid, isSubmitting },
    ...form
  } = useForm<FiscalRepresentationDto>({
    mode: 'onTouched',
  });

  useEffect(() => {
    form.reset(fiscalRepresentation.data ?? undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fiscalRepresentation.data?.processFiscalRepresentationId]);

  const defaultColumnLayout = { xs: 12, sm: 6, md: 4, lg: 3, xl: 2 };

  return (
    <Box>
      <Typography
        variant="h6"
        mb={2}
      >
        {t('Fiscal representation')} {fiscalRepresentation.isLoading && <CircularProgress size="1em" />}
      </Typography>

      <form
        onSubmit={form.handleSubmit(async ({ processFiscalRepresentationId, ...data }) => {
          try {
            if (processFiscalRepresentationId) {
              await ProcessService.updateFiscalRepresentation(data);

              notifications.addSuccess(t('Fiscal representation updated.'));
            } else {
              await ProcessService.createFiscalRepresentation({ ...data, processId });

              notifications.addSuccess('Fiscal representation created.');
            }

            const updatedData = await fiscalRepresentation.mutate();

            form.reset(updatedData);
          } catch (error) {
            notifications.addError(error);
          }
        })}
      >
        <Grid
          container
          spacing={1}
        >
          <Grid
            item
            {...defaultColumnLayout}
          >
            <FormTextField
              name="name"
              label={t('Name')}
              required
              fullWidth
              control={form.control}
            />
          </Grid>

          <Grid
            item
            {...defaultColumnLayout}
          >
            <FormTextField
              name="street"
              label={t('Street')}
              required
              fullWidth
              control={form.control}
            />
          </Grid>

          <Grid
            item
            {...defaultColumnLayout}
          >
            <FormTextField
              name="postCode"
              label={t('Zip')}
              required
              fullWidth
              control={form.control}
            />
          </Grid>

          <Grid
            item
            {...defaultColumnLayout}
          >
            <FormTextField
              name="city"
              label={t('City')}
              required
              fullWidth
              control={form.control}
            />
          </Grid>

          <Grid
            item
            {...defaultColumnLayout}
          >
            <Controller
              control={form.control}
              rules={{ required: true }}
              name="countryCode"
              render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                <FormControl
                  fullWidth
                  required
                >
                  <InputLabel id="country-code-label">Country code</InputLabel>
                  <SelectCountryCode
                    labelId="country-code-label"
                    name={name}
                    error={!!error}
                    onChange={onChange}
                    value={value ?? ''}
                    label={t('Country code')}
                  />
                </FormControl>
              )}
            />
          </Grid>

          <Grid
            item
            {...defaultColumnLayout}
          >
            <Controller
              control={form.control}
              rules={{ required: true }}
              name="destinationCountry"
              render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                <FormControl
                  fullWidth
                  required
                >
                  <InputLabel id="destination-country-label">{t('Destination country')}</InputLabel>
                  <SelectCountryCode
                    labelId="destination-country-label"
                    name={name}
                    error={!!error}
                    onChange={onChange}
                    value={value ?? ''}
                    label={t('Destination country')}
                  />
                </FormControl>
              )}
            />
          </Grid>

          <Grid
            item
            {...defaultColumnLayout}
          >
            <FormTextField
              name="vatNumber"
              label={t('VAT-ID for batch inv.')}
              fullWidth
              control={form.control}
            />
          </Grid>

          <Grid
            item
            {...defaultColumnLayout}
          >
            <FormTextField
              name="voecNumber"
              label={t('VOEC number')}
              fullWidth
              control={form.control}
            />
          </Grid>

          <Grid
            item
            {...defaultColumnLayout}
          >
            <FormTextField
              name="customsCreditAccount"
              label={t('CCA for batch inv.')}
              fullWidth
              control={form.control}
            />
          </Grid>

          <Grid
            item
            xs={12}
            textAlign="right"
          >
            <LoadingButton
              loading={isSubmitting || fiscalRepresentation.isLoading}
              loadingPosition="start"
              type="submit"
              variant="contained"
              color="secondary"
              startIcon={<SaveIcon />}
              disabled={!isValid || isSubmitting || !isDirty || fiscalRepresentation.isLoading}
            >
              {t('Update fiscal representation')}
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default FiscalRepresentation;
