import { CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React from 'react';
import { ExchangeRateService } from '../../../services/exchange-rate-service/exchange-rate.service';
import { useTranslation } from 'react-i18next';

type Props = {};

const ExchangeRateTable: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { data: exchangeRates, isLoading } = ExchangeRateService.useExchangeRates();

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>{t('Base currency')}</TableCell>
            <TableCell>{t('Destination currency')}</TableCell>
            <TableCell align="right">{t('Rate')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {exchangeRates
            .sort((a, b) => a.destinationCurrency.localeCompare(b.destinationCurrency))
            .map((rate) => (
              <TableRow key={rate.exchangeRateId}>
                <TableCell>{rate.baseCurrency}</TableCell>
                <TableCell>{rate.destinationCurrency}</TableCell>
                <TableCell align="right">{rate.rate.toFixed(6)}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ExchangeRateTable;
