import React from 'react';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import PendingIcon from '@mui/icons-material/Pending';

import { ReducedJobDto, getJobProgressState } from '../../../services/job-service/job.service';
import { useTranslation } from 'react-i18next';

export interface IJobStateProps {
  job: ReducedJobDto;
}

const DisplayJobState = (props: IJobStateProps) => {
  const { t } = useTranslation('jobs');
  const state = getJobProgressState(props.job);
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
    >
      {state === 'completed' && <CheckCircleIcon color="success" />}
      {state === 'failed' && <ErrorIcon color="error" />}
      {state === 'waiting' && <PendingIcon color="info" />}
      {state === 'active' && props.job.progress && (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
          <CircularProgress
            variant="determinate"
            value={props.job.progress}
          />
          <Typography
            fontSize={'small'}
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {Math.round(props.job.progress)}%
          </Typography>
        </Box>
      )}
      <Typography sx={{ display: { xs: 'none', md: 'inline' } }}>
        {state === 'completed' && t('Completed')}
        {state === 'failed' && t('Failed')}
        {state === 'waiting' && t('Waiting')}
        {state === 'active' && t('Active')}
      </Typography>
    </Stack>
  );
};

export default DisplayJobState;
