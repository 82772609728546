import { Alert, AlertTitle } from '@mui/material';
import React from 'react';
import TourWarningIcon, { TourWarningType } from './TourWarningIcon';
import { useTranslation } from 'react-i18next';

type Props = {};

const EmptyTourAlert: React.FC<Props> = () => {
  const { t } = useTranslation();

  return (
    <Alert
      severity="error"
      icon={<TourWarningIcon type={TourWarningType.TOUR_EMPTY} />}
    >
      <AlertTitle>{t('Empty tour')}</AlertTitle>
      {t(
        'All shipments do not contain any items or these are not relevant to customs. To avoid problems, these tours should generally be removed from the batch and completed before customs clearance takes place.',
      )}
    </Alert>
  );
};

export default EmptyTourAlert;
