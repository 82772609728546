import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { AutocompleteChangeReason } from '@mui/material/Autocomplete';
import { Box } from '@mui/material';
import { countryToFlag } from '../../../shared/helper/country';
import { useNotifications } from '../../../hooks/useNotifications';

import * as ProcessSettingsService from '../../../services/process-settings-service/processSettings.service';
import { ConfirmDeleteLanguageModal } from './ConfirmDeleteLanguageModal';
import { IIsoLanguage } from '../../../shared/backend';
import { useTranslation } from 'react-i18next';

/**
 * Languages that should be selectable for return frontend
 */
export const AVAILABLE_LANGUAGES = {
  de: 'German',
  en: 'English',
  es: 'Spanish',
  fr: 'French',
  it: 'Italian',
};

export interface LanguageOption {
  languageCode: IIsoLanguage;
  label: string;
}

export enum LanguageToCountryCode {
  de = 'DE',
  en = 'GB',
  es = 'ES',
  fr = 'FR',
  it = 'IT',
}

enum ChangeReason {
  SELECT_OPTION = 'selectOption',
  REMOVE_OPTION = 'removeOption',
  CREATE_OPTION = 'createOption',
  CLEAR = 'clear',
  BLUR = 'blur',
}

export interface LanguagePickerProps {
  processId: number;
  languages: ProcessSettingsService.LanguageDto[];
  onChangeLanguages: () => void;
}

const LanguagePicker: React.FC<LanguagePickerProps> = ({ processId, languages, onChangeLanguages }) => {
  const { t } = useTranslation();
  const notificationHandler = useNotifications();
  const [languagesToDelete, setLanguagesToDelete] = useState<ProcessSettingsService.LanguageDto[]>([]);

  const getLanguageOptions = (): LanguageOption[] => {
    return Object.entries(AVAILABLE_LANGUAGES).map(([key, val]) => {
      return {
        languageCode: key as IIsoLanguage,
        label: val,
      };
    });
  };

  const handleOnChange = (
    event: React.SyntheticEvent,
    selectedOptions: LanguageOption[],
    reason: AutocompleteChangeReason,
  ) => {
    const selectedLanguages = selectedOptions.map((lang) => lang.languageCode);

    if (reason === ChangeReason.SELECT_OPTION) {
      handleLanguageAdd(selectedLanguages);
    } else if (reason === ChangeReason.REMOVE_OPTION) {
      handleLanguageRemove(selectedLanguages);
    } else if (reason === ChangeReason.CLEAR) {
      handleLanguageRemove([]);
    }
  };

  const handleLanguageAdd = async (selectedOptions: IIsoLanguage[]) => {
    const newLanguages = selectedOptions.filter(
      (selected) => !languages.find(({ languageCode }) => languageCode === selected),
    );

    if (newLanguages.length) {
      await postLanguage({ languageCode: newLanguages[0] });
    }
  };

  const handleLanguageRemove = async (selectedOptions: IIsoLanguage[]) => {
    // Find language or languages that should be removed
    const languagesToBeRemoved = languages.filter((language) => !selectedOptions.includes(language.languageCode));

    setLanguagesToDelete(languagesToBeRemoved);
  };

  const confirmLanguageDelete = async (languagesToDelete: ProcessSettingsService.LanguageDto[]) => {
    setLanguagesToDelete([]);
    for (const language of languagesToDelete) {
      await deleteLanguage(language.languageId);
    }
  };

  const postLanguage = async (body: ProcessSettingsService.CreateLanguageDto) => {
    try {
      await ProcessSettingsService.postLanguage(body, processId);
      onChangeLanguages();
    } catch (error) {
      notificationHandler.addError(error);
    }
  };

  const deleteLanguage = async (languageId: number) => {
    try {
      await ProcessSettingsService.deleteLanguage(processId, languageId);
      onChangeLanguages();
    } catch (error) {
      notificationHandler.addError(error);
    }
  };

  const languageOptions = getLanguageOptions();
  const selectedOptions = languageOptions.filter((lang) =>
    languages.map((l) => l.languageCode).includes(lang.languageCode),
  );

  return (
    <>
      <Autocomplete
        multiple
        disableCloseOnSelect
        value={selectedOptions}
        onChange={handleOnChange}
        options={languageOptions}
        autoHighlight
        getOptionLabel={(option) =>
          `${countryToFlag(LanguageToCountryCode[option.languageCode as unknown as keyof typeof LanguageToCountryCode])} ${option.label}`
        }
        renderOption={(props, option) => (
          <Box
            component="li"
            {...props}
          >
            {countryToFlag(LanguageToCountryCode[option.languageCode as unknown as keyof typeof LanguageToCountryCode])}
            &nbsp;{option.label}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('Languages')}
            variant="outlined"
          />
        )}
        isOptionEqualToValue={(option, value) => option.languageCode === value.languageCode}
      />
      {languagesToDelete.length > 0 && (
        <ConfirmDeleteLanguageModal
          languagesToDelete={languagesToDelete}
          handleClose={() => setLanguagesToDelete([])}
          handleConfirm={() => confirmLanguageDelete(languagesToDelete)}
        ></ConfirmDeleteLanguageModal>
      )}
    </>
  );
};

export default LanguagePicker;
